import { LoadingIcon } from ".";
import { useEffect, useState } from "react";
import { PREDICTION_LIST_V3 } from "src/config/predictions";
import { history } from "src/stores";
import { Tag } from "./tag";

interface FilterValues {
  [key: string]: string; // Assuming all values are strings
}

export const PredictionV3LiveList = ({ isMobile }: any) => {
  const [data, setData] = useState(
    PREDICTION_LIST_V3.filter((item) => item?.isSelectable)
  );
  const [filterValues, setFilterValues] = useState<FilterValues>({
    games: "all",
    categories: "all",
    live: "all",
  });
  const [loading] = useState<boolean>(false);

  const [filterPrediction, setFilterPrediction] = useState<[] | null>();

  useEffect(() => {
    // const data = PREDICTION_LIST.filter((item) => item?.type.includes(tab));
    return setData(data);
  }, [data]);

  useEffect(() => {
    let filteringItem: any = data;

    for (const [key, value] of Object.entries(filterValues)) {
      // Check if the value is "ALL"; if so, ignore this filter condition.
      if (value.toUpperCase() === "ALL") {
        continue;
      }

      if (key.toUpperCase() === "GAMES") {
        filteringItem = filteringItem?.filter((task: any) =>
          task.type.includes(value.toLowerCase())
        );
      }

      // if (key.toUpperCase() === "CATEGORIES") {
      //   filteringItem = filteringItem?.filter((task: any) =>
      //     task.mission_type.toLowerCase().includes(value.toLowerCase())
      //   );
      // }
    }

    return setFilterPrediction(filteringItem);
  }, [filterValues, data]); // React to changes in filterValues or tasks.

  const DropdownInput = ({ label, values, disable = false }: any) => {
    return (
      <select
        // defaultValue={label}
        value={filterValues[label.toLowerCase()]}
        className="w-[140px] h-[34px] md:w-[200px] md:h-[56px] form-select bg-black mr-4 text-white border-b-2 border-gray-600 cursor-pointer"
        onChange={(e) =>
          setFilterValues({
            ...filterValues,
            [label.toLowerCase()]: e.target.value,
          })
        }
      >
        <option value={"all"} disabled className="option-label">
          {label}
        </option>
        {!disable &&
          values.map((value: string, index: number) => {
            return (
              <option className="option-label" key={`${label}-${index}`}>
                {value}
              </option>
            );
          })}
      </select>
    );
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row md:items-center">
        <h1 className="page-title flex-1 relative z-[1000]">
          Live <span className="text-v2primary">PREDICTIONS</span>
        </h1>
        {!isMobile && (
          <div className="flex flex-1 md:items-center md:justify-end">
            <DropdownInput label="GAMES" values={["ALL", "web2", "web3"]} />
            <DropdownInput label="CATEGORIES" values={["ALL"]} />
            <DropdownInput label="LIVE" values={["ALL"]} disable={true} />
          </div>
        )}
      </div>

      <div className="flex flex-col h-full md:flex-row md:overflow-y-auto mt-m relative">
        {!isMobile && (
          <div className="absolute inset-y-0 left-0 w-[120px] bg-gradient-to-r from-black to-transparent z-[999]" />
        )}
        {!isMobile && (
          <div className="absolute inset-y-0 right-0 w-[120px] bg-gradient-to-l from-black to-transparent z-[999]" />
        )}

        <div className="flex flex-col md:flex-row md:flex-wrap">
          {loading && (
            <div
              key="loading-icon"
              className="flex w-full items-center justify-center"
            >
              <LoadingIcon />
            </div>
          )}
          {filterPrediction?.map((item, index) => {
            // Adjust the number here to limit the total number of items rendered
            const {
              gameId,
              avatar,
              type = ["web2"],
              name,
              desc,
              isEnded,
              isLive,
            } = item;
            return (
              <div
                className="flex flex-row  md:flex-col lg:flex-col items-start lg:items-center justify-between 
                w-full h-[105px] md:h-auto md:w-[379px] lg:w-[444px] mb-m sm:mr-s cursor-pointer rounded-m overflow-hidden relative bg-[#1D1D1D]"
                onClick={() => history.push("/prediction/" + gameId)}
                key={`live-${gameId}`}
                style={{
                  position: "relative",
                }}
              >
                {/* Left/Top Image */}
                <div
                  className="min-w-[200px] h-full md:w-full md:h-[201px] bg-cover bg-no-repeat bg-center p-s flex flex-row"
                  style={{
                    backgroundImage: `url(${avatar})`,
                  }}
                >
                  {/* reward type label */}
                  <Tag tag={type[0]} />
                  {isLive && <Tag tag="Live" />}
                  {isEnded && <Tag tag="Ended" />}
                </div>

                {/* Right/Bottom Content */}
                <div className="flex flex-col w-full p-s">
                  <p className="big-content">{name}</p>
                  <p
                    className="blender-medium text-[10px] sm:text-[12px]"
                    style={{
                      color: "white",
                    }}
                  >
                    {desc}
                  </p>
                  {!isMobile && (
                    <div className="flex flex-row mt-m">
                      <p className="big-content">
                        {isEnded ? "RESULT" : `PREDICT NOW`}
                        {" >"}
                      </p>
                    </div>
                  )}

                  {isMobile && (
                    <div className="mt-s flex">
                      <Tag tag={type[0]} />
                      {isLive && <Tag tag="Live" />}
                      {isEnded && <Tag tag="Ended" />}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
