import { Modal } from "antd";
import { CloseButton } from "./close-btn";
import { openWarningNotification } from "./notification";
import { useAirdropStore, useTwitterStore } from "src/stores";

export const ModalConfirmUnlockReferral = (props: any) => {
  const { isModalOpen, setIsModalOpen, handleTwitterLogin, profile, address } =
    props;

  const { getTwitterToken } = useTwitterStore();
  const { unlockReferral } = useAirdropStore();

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleTwitterErr = async () => {
    openWarningNotification("You need to re-connect the twitter again");
    // jwt = await getTwitterToken();
    await handleTwitterLogin(true);
    return;
  };

  const handleConfirm = async () => {
    let jwt = null;
    try {
      if (!address) {
        openWarningNotification("Please connect to Wallet");
        return;
      }

      if (!profile?.twitter_connected) {
        openWarningNotification("Please authorize Twiiter Login");
        return;
      }

      if (profile?.referral_tweet_id) {
        openWarningNotification("You have done this task!");
        return;
      }

      if (!profile?.twitter_jwt) {
        jwt = await getTwitterToken();
        if (!jwt) return handleTwitterErr();
      }
      if (profile?.twitter_jwt) jwt = profile?.twitter_jwt;

      const result = await unlockReferral(jwt);
      if (result === "unknown_error") {
        return handleTwitterErr();
      }
      window?.location?.reload();
    } catch (e) {
      handleTwitterErr();
      return;
    }
  };

  return (
    <Modal
      title={``}
      visible={isModalOpen}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={null}
      centered={true}
      bodyStyle={{
        backgroundColor: "black",
        borderRadius: 10,
      }}
    >
      {/* Close btn */}
      <CloseButton handleCancel={handleCancel} />

      <div className="flex flex-col justify-center items-center min-h-[200px]">
        {/* Title */}
        <h3 className="card-title">Unlock Referral</h3>
        {/* Confirmation */}
        <div className="mt-m">
          <p className={`content text-white text-center`}>
            Do you want to create a tweet to unlock referral ?
          </p>
          {/* Confirm Button */}
          <div className="mt-m">
            <button
              className="bg-gradient-to-r from-[#F99433] to-[#FE5218] text-white  px-[20px] py-[10px] border-[0px] mx-auto w-full md:w-[300px] justify-center flex blender-medium"
              onClick={() => handleConfirm()}
            >
              CONFIRM
            </button>
          </div>

          <div className="mt-s">
            <p
              className={`content text-white cursor-pointer text-center uppercase`}
              onClick={() => handleCancel()}
            >
              CANCEL
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
